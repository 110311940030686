@import 'core/bulma-config';
@import 'lib/bulma/bulma';
@import "lib/bulma-extensions/bulma-switch/src/sass/index";
@import "core/all";
@import "components/all";


:root {
	--page-bg: #f5f5fa;
	--box-bg: #ffffff;
	--text-black: #282828;
	--text-grey: #8b8b8d;
	--shadow: rgba(64, 64, 64, 0.3);
	--select: #fff1d6;
	--message-danger-bg: #ffeeed;
	--message-text: #282828;
	--danger: #ff515c;
	--button-main: #4543a6;
	--button-main-hover: #373584;
	--button-main-disabled: #8383bd;
}

body {
	font-family: "Inter", sans-serif;
	overflow: hidden;

	&.dark-theme {
		--page-bg: #282828;
		--box-bg: rgb(104, 104, 104);
		--text-black: #ececec;
		--text-grey: #8b8b8b;
		--shadow: rgba(255, 255, 255, 0.3);
		--select: rgb(128, 128, 128);
		--button-main: #aed1ff;

		#header.menu.darkmode {
			.light {
				display: initial;
			}
			.dark {
				display: none;
			}
		}
	}

	background-color: var(--page-bg);
	background-image: url("./../icons/login-bg.svg");
	background-repeat: no-repeat;
	background-size: cover;

	color: var(--text-black);
	min-width: 380px;

	.hero-body {
		justify-content: center;
	}

	&.step1 {
		#login .ncard {
			transform: rotate(-2.5deg);
			transition: transform 250ms ease-out;
		}

		#forgotYourPasword,
		#newPasswordConfirm {
			top: calc(50% - 1em);
			transition: top 250ms ease-out;
		}
	}
	&.step1-out {
		#login .ncard {
			transform: rotate(0);
		}
		#forgotYourPasword {
			top: 150%;
		}
	}

	&.step2 {
		#login .ncard {
			transform: rotate(-5deg);
		}
		#forgotYourPasword .ncard {
			transform: rotate(-2.5deg);
		}
		#forgotPasswordConfirmation {
			top: calc(50% - 0.5em);
			transition: top 250ms ease-out;
		}
	}
}

@mixin box-shadow {
	box-shadow: 0 0 10px 0 var(--shadow);
}

@media screen and (max-width: 600px) {
	body {
		background: none;
	}

	.hero-body {
		padding: 0;
		align-items: flex-start !important;
	}

	#login {
		width: 100%;

		.login-card {
			box-shadow: none !important;
		}
	}
}

a {
	color: var(--button-main);
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 0.22px;
	text-decoration: none;
	text-transform: uppercase;

	&:hover,
	&:visited {
		color: var(--button-main-hover);
	}
}

.ncard {
	width: 380px;
	padding: 16px 40px 40px 40px;
	background: var(--box-bg);
	@include box-shadow;
	border-radius: 6px;

	&-header {
		.logo {
			margin: 24px auto;
			text-align: center;
		}

		.messages {
			font-size: 13px;
			min-height: 54px;

			._message {
				display: flex;
				flex-flow: row;
				align-items: center;
				color: var(--message-text);
				padding: 13px;
				font-weight: 700;
				line-height: 1.4em;
				border-radius: 6px;

				._message-icon {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 1.75rem;
					height: 1.75rem;
					border: 2px solid var(--text-black);
					border-radius: 50%;
					margin-right: 11px;
					flex-shrink: 0;
				}

				&.is-danger {
					background: var(--message-danger-bg);

					._message-icon {
						border-color: var(--danger);
						stroke: var(--danger);
					}
				}
			}
		}

		h1 {
			font-size: 1rem;
			font-weight: 700;
			margin: 16px auto 32px auto;
			text-align: center;
		}
	}

	&-body {
		min-height: 270px;

		.field {
			label {
				display: flex;
				margin-bottom: 8px;
				font-size: 12px;
				font-weight: 700;
				letter-spacing: 0.22px;
				text-transform: uppercase;
				color: var(--text-grey);

				.togglePassword {
					margin-left: auto;
					cursor: pointer;

					.show {
						display: none;
					}

					&.show {
						.show {
							display: initial;
						}

						.hide {
							display: none;
						}
					}
				}
			}

			input {
				&.error {
					border: 1px solid var(--danger);
				}
			}

			.invalid-feedback {
				color: var(--danger);
				font-size: 14px;
				text-align: right;
				line-height: 1.4em;
				height: 1.4em;
			}
		}

		.description {
			font-size: 13px;
			font-weight: 500;

			p {
				margin: 1em 0;
			}
		}
	}

	&-footer {
		text-align: right;

		.button {
			font-size: 12px;
			font-weight: 700;
			letter-spacing: 0.22px;
			text-transform: uppercase;
			border-radius: 100px;

			&.is-primary {
				background-color: var(--button-main);

				&:hover {
					background-color: var(--button-main-hover);
				}
				&:disabled {
					background-color: var(--button-main-disabled);
				}
			}
		}
	}
}

.hero-body {
	position: relative;

	> section {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

#login,
#newPassword {
	header {
		#menu {
			display: flex;
			justify-content: flex-end;

			.dropdown-trigger {
				font-size: 0.75rem;
				font-weight: bold;
				line-height: 1.5em;
				letter-spacing: 0.22px;
				text-transform: uppercase;
				color: var(--text-grey);

				&:after {
					content: "";
					display: inline-block;
					background: url("../icons/icon-chevron.svg") no-repeat
						center;
					background-size: contain;
					width: 10px;
					height: 1.5em;
					vertical-align: bottom;
				}
			}

			.dropdown-menu {
				min-width: auto;

				.dropdown-content {
					background-color: var(--box-bg);
					@include box-shadow;
					width: auto;

					.dropdown-item {
						color: inherit;

						a {
							display: block;
							color: inherit;
						}

						&.is-active {
							background-color: var(--select);
							font-weight: bold;
						}
					}
				}
			}

			.darkmode {
				margin-left: 8px;
				color: var(--text-black);

				.light {
					display: none;
				}
			}
		}
	}

	footer {
		display: flex;
		margin: 8px auto;
		font-size: 12px;
		text-align: center;
		justify-content: center;
		align-content: center;
		color: var(--text-grey);

		a {
			margin-left: 8px;
		}
	}
}

#forgotYourPasword {
	top: -100%;
}
#forgotPasswordConfirmation {
	top: -100%;

	.email-push {
		margin: 55px auto;
		text-align: center;
	}
}
#newPasswordConfirm {
	top: -100%;

	.description {
		text-align: center;

		.image {
			display: block;
			margin: 45px auto;

			img {
				width: 111px;
				margin: 0 auto;
			}
		}

		a {
			display: inline-block;
			margin-top: 15px;
		}
	}
}

// todo: also in site - merge?
.help-icon[title]::after {
	display: inline-block;
	content: "";
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	background: url("../icons/tip.svg") center;
	object-fit: contain;
	vertical-align: text-bottom;
	margin-left: 0.5em;
}
